<template>
  <popupCharge
    :activation="charge"
    v-if="charge"
  />
  <div class="h-auto bg-white w-full pt-6 pb-8 pl-8 pr-8">
    <div class="">
      <div class="all">
        <div class="w-full flex text-6b text-c16 font-c7 text-left mb-6 text-titre">
          <div class="w-1/3">
            Nom
          </div>
          <div class="w-1/4 ml-4">
            Date de la demande
          </div>
          <div class="w-1/5 ml-4">
            Traitement DGI
          </div>
          <div class="w-1/5 ml-4">
            Statut
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div
          class="text-center mt-10"
          v-if="tracker.length === 0 && !charge"
        >
          Aucun commerce pour le moment
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div>
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-10 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
          :data="icons.prev"
          height="50"
          width="50"
          class="icone cursor-pointer ml-4"
          original
          @click="prev"
        />
        <icon
          :data="icons.next"
          height="50"
          width="50"
          class="icone cursor-pointer ml-4"
          original
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import down from '../../../assets/icons/down.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import popupCharge from '../../popup/popupCharge'

export default {
  name: "Index",
  components: {
    liste,
    popupCharge
  },

  props: {
    search: {
      type: String,
      default: null
    },
    filtrage: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        prev,
        next
      },
      client: [],
      charge: true,
      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      devisPaginate: [],
      temoin: null,
      dataClient: [],
      allClient: [],
    }
  },

  computed: {
    tracker: function () {
      let retour = this.dataClient

      if (this.temoin !== this.filtrage) {
        this.allDemandeParginate()
      }
      this.$emit('stat', retour.length + ' / ' + this.total)

      return retour
    }
  },

  created () {
    this.allDemandeParginate()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allDemandeParginate()
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allDemandeParginate()
      }
    },

    activeCharge(value){
      this.charge = value
    },

    allDemandeParginate () {
      this.temoin = this.filtrage
      this.charge = true
      const body = {
        limit: this.limite,
        page: this.page,
        dgiStatus: this.filtrage === 'EN COURS' ? 'PENDING' : this.filtrage === 'APPROUVÉE' ? 'APPROVED' : 'REJECTED'
      }

      http.post(apiroutes.baseURL + apiroutes.allDemande, body)
          .then(response => {
            this.dataClient = response.rows
            this.total = response.count
            this.totalPage = parseInt(response.count / this.limite)
            if (this.totalPage === 0) {
              this.totalPage = 1
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
