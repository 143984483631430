<template>
  <div class="p-6 pr-6 all">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          Demandes DGI ({{ stat }})
        </div>

        <div class="w-1/2 flex justify-end">
          <!--          <div class="w-1/2">-->
          <!--            <inputo :icon="icons.search" @info="retourSearch" height="50px" :searchMode="true" placeholder="Rechercher..." />-->
          <!--          </div>-->

          <div class="w-1/3 ml-4">
            <multiselect
              :option="allCategorie"
              :value-t="allCategorie[0]"
              height="50px"
              size-defil="13px"
              @info="backFiltre"
            />
          </div>
        </div>
      </div>

      <div class="mt-6">
        <global-view
          :search="recherche"
          :filtrage="valueCategorie"
          @filt="filtrage = null"
          @stat="backStat"
          @user="backUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import globalView from '../../component/cdevs/demandes/globalData'
// import inputo from '../../component/helper/form/input'
import multiselect from '../../component/helper/form/multiselect'

export default {
  name: "Index",

  components: {
    globalView,
    // inputo,
    multiselect,
  },

  data () {
    return {
      icons: {
        search
      },
      allUser: [],
      recherche: null,
      valueCategorie: null,
      stat: '',
      allCategorie: ['EN COURS', 'APPROUVÉE', 'REJETÉE'],
      admin: null
    }
  },

  created () {
    this.admin = this.$store.getters.trackUser.user
  },

  methods: {
    backStat (a) {
      this.stat = a
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    backFiltre(s) {
      if (s !== 'Catégorie'){
        this.valueCategorie = s
      }
    },

    backUser (answer) {
      this.allUser = answer
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
