<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/3 flex items-center">
        <icon
          :data="icons.entreprise"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />

        <div>{{ item.fullName }}</div>
      </div>

      <div class="w-1/4 ml-4 flex items-center font-c7">
        {{ new Date(item.updatedAt).toLocaleDateString() }}
      </div>

      <div class="w-1/5 ml-4">
        <div
          v-if="item.dgiStatus === 'PENDING'"
        >
          Demande en attente
        </div>
        <div
          v-if="item.dgiStatus === 'REJECTED'"
        >
          Non recevable
        </div>
        <div
          v-if="item.dgiNim"
        >
          Token (NIM) envoyé
        </div>
      </div>

      <div class="w-1/5 ml-4">
        <div class="w-2/5">
          <boutton
            v-if="item.dgiStatus === 'PENDING'"
            label="En cours"
            background="#FFB736"
            size="14px"
            radius="40px"
            height="30px"
          />
        </div>

        <div class="w-2/5">
          <boutton
            v-if="item.dgiStatus === 'REJECTED'"
            label="Rejetée"
            background="#F82626"
            size="14px"
            radius="40px"
            height="30px"
          />
        </div>

        <div class="w-2/5">
          <boutton
            v-if="item.dgiStatus === 'APPROVED'"
            label="Traitée"
            background="#2B9E7C"
            size="14px"
            radius="40px"
            height="30px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/ic-entreprise.svg'
import boutton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    boutton
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        entreprise
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
